<template>
    <div>
        <div class="main">
            <div class="search">
                <div class="refresh" @click="search"><i class="fa fa-refresh"></i></div>
                <!--파트너 선택-->
                <partner-selector-comp @setPartnerId="setPartnerId"></partner-selector-comp>
                <!--검색조건선택-->
                <el-select size="mini" style="width: 100px;margin-left: 5px" v-model="applyCash.byField"
                           placeholder="검색조건">
                    <el-option :value="managerConst.User.SEARCH_BY_USERNAME" label="아이디"></el-option>
                    <el-option :value="managerConst.User.SEARCH_BY_NICKNAME" label="닉네임"></el-option>
                    <el-option :value="managerConst.User.SEARCH_BY_REALNAME" label="입금명"></el-option>
                </el-select>
                <el-input v-model="applyCash.searchString" size="mini" style="width: 150px;margin-left: 5px"
                          placeholder="검색내용입력"></el-input>
                <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>

                <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색</el-button>

                <!--상태-->
                <el-select size="mini" style="width: 110px;margin-left: 20px" v-model="applyCash.status"
                           @change="search" placeholder="상태선택">
                    <el-option :value="null" label="전체">전체</el-option>
                    <el-option :value="managerConst.ApplyCash.STATUS_WAITING" label="미확인">미확인</el-option>
                    <el-option :value="managerConst.ApplyCash.STATUS_CHECKING" label="확인중">확인중</el-option>
                    <el-option :value="managerConst.ApplyCash.STATUS_COMPLITE" label="완료">완료</el-option>
                    <el-option :value="managerConst.ApplyCash.STATUS_CANCEL" label="취소">취소</el-option>
                </el-select>


            </div>
            <div class="data">
                <div style="padding: 5px 0;font-weight: bold;width: 100%">
                    <span style="color: blue">미확인금액: {{watingamount|comma}}, 확인중금액: {{checkingamount|comma}}</span> | 회원:{{totalUserCount}}명, 총{{totalCount}}회, 총출금액:{{totalCash |comma}}
                </div>
                <el-table
                        :data="applyCashList"
                        style="width: 100%"
                        max-height="730"
                        border>

                    <el-table-column
                            fixed
                            label="총판"
                            width="120">
                        <template slot-scope="scope">
                            <div v-if="null != scope.row.agent2">
                                {{scope.row.agent2.nickname}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="닉네임"
                            width="110">
                        <template slot-scope="scope">
                            <el-button size="mini" type="success" v-if="scope.row.userOrPartner == managerConst.ApplyCash.TYPE_USER"
                                       @click="editUser(scope.row.userId,managerConst.MODE_EDIT)"
                                       style="cursor: pointer">
                                {{scope.row.user.nickname}}
                            </el-button>
                            <el-button size="mini" type="primary" v-if="scope.row.userOrPartner == managerConst.ApplyCash.TYPE_PARTNER"
                                       @click="editAgent(scope.row.partnerId,managerConst.MODE_EDIT)"
                                       style="cursor: pointer">
                                {{scope.row.agent.nickname}}
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="금액"
                            width="90">
                        <template slot-scope="scope">
                            <i class="fa fa-coins" v-if="scope.row.consiterecharge == managerConst.YES"></i>
                            <span style="display: inline-block;padding: 2px;border-radius: 3px;width: 95%" :class="{'bg-red':scope.row.isrepeatamount == managerConst.YES,'text-blue':scope.row.amount>=100000}">{{scope.row.amount|comma}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="보너스"
                            width="90">
                        <template slot-scope="scope">
                                <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_YES1"
                                      class="text-primary">
                                                        <i class="fa fa-check-circle">[스포츠]</i>
                                                    </span>
                            <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_YES2"
                                  class="text-primary">
                                                        <i class="fa fa-check-circle"></i>[미니게임]
                                                    </span>
                            <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_YES3"
                                  class="text-primary">
                                                        <i class="fa fa-check-circle"></i>[카지노/슬롯]
                                                    </span>
                            <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_NOT">x</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            fixed
                            label="롤링"
                            width="240">
                        <template slot-scope="scope">
                            {{scope.row.rollinginfo}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="은행명"
                            width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.user">{{scope.row.user.bank}}</span>
                            <span v-if="scope.row.agent">{{scope.row.agent.bank}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="예금주"
                            width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.user">{{scope.row.user.beneficiary}}</span>
                            <span v-if="scope.row.agent">{{scope.row.agent.beneficiary}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="계좌번호"
                            width="130">
                        <template slot-scope="scope">
                            <span v-if="scope.row.user">{{scope.row.user.acNo}}</span>
                            <span v-if="scope.row.agent">{{scope.row.agent.acNo}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="신청일짜"
                            width="110">
                        <template slot-scope="scope">
                            {{scope.row.createTime|datef('MM월DD일 HH:mm')}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="상태"
                            width="80">
                        <template slot-scope="scope">
                                <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_WAITING"
                                      class="badge badge-primary">미확인</span>
                            <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_CHECKING"
                                  class="badge badge-primary">확인중</span>
                            <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_COMPLITE"
                                  class="text-blue">완료</span>
                            <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_CANCEL" class="text-red">취소</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="Action"
                            width="280">
                        <template slot-scope="scope">
                            <div v-if="scope.row.status != managerConst.ApplyCash.STATUS_COMPLITE && scope.row.status != managerConst.ApplyCash.STATUS_CANCEL">
                                <el-button size="mini" type="primary"
                                           v-if="scope.row.status != managerConst.ApplyCash.STATUS_CHECKING"
                                           @click="updateStatus(scope.row.id,managerConst.ApplyCash.STATUS_CHECKING,'확인중처리',managerConst.DISABLE)">
                                    확인중처리
                                </el-button>
                                <!--                                                        <button ng-click="updateStatus(item.id,STATUS_COMPLITE,'성공및코인차감',ENABLE)"-->
                                <!--                                                                class="btn btn-sm btn-success"-->
                                <!--                                                                style="margin-left: 10px;border: 3px solid #0000ff" ng-bind="'성공및코인차감'">-->
                                <!--                                                        </button>-->
                                <el-button size="mini" type="success"
                                           @click="updateStatus(scope.row.id,managerConst.ApplyCash.STATUS_COMPLITE,'성공처리',managerConst.DISABLE)"
                                >성공처리
                                </el-button>
                                <el-button size="mini" type="danger"
                                           @click="updateStatus(scope.row.id,managerConst.ApplyCash.STATUS_CANCEL,'취소처리',managerConst.DISABLE)">
                                    취소처리
                                </el-button>
                                <el-button v-if="scope.row.display == managerConst.DISABLE"
                                           @click="updateDisplay(scope.row.id,managerConst.ENABLE)"
                                           size="mini" type="danger">
                                    삭제복구
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="번호"
                            width="55">
                        <template slot-scope="scope">
                            {{scope.row.id}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="통장"
                            width="110">
                        <template slot-scope="scope">
                            <div v-if="scope.row.userOrPartner == managerConst.ApplyCash.TYPE_USER">
                                        <span v-if="scope.row.user.banktype == managerConst.User.BANK_TYPE_BANKCARDID">
                                    <span v-if="scope.row.ccrt">{{scope.row.ccrt.title}}</span>
                                </span>
                                <span v-if="scope.row.user.banktype == managerConst.User.BANK_TYPE_FACK_CARD"
                                      class="text-primary">가상계좌 1</span>
                                <span v-if="scope.row.user.banktype == managerConst.User.BANK_TYPE_FACK_CARD_2"
                                      class="text-danger">가상계좌 2</span>
                                <span v-if="scope.row.user.banktype == managerConst.User.BANK_TYPE_FACK_CARD_3"
                                      class="text-purple">가상계좌 3</span>
                                <span v-if="scope.row.user.banktype == managerConst.User.BANK_TYPE_FACK_CARD_4"
                                      class="">가상계좌 4</span>
                                <span v-if="scope.row.user.banktype == managerConst.User.BANK_TYPE_NORMAL">수동</span>
                            </div>

                        </template>
                    </el-table-column>
                    <el-table-column
                            label="Operator"
                            width="80">
                        <template slot-scope="scope">
                            {{scope.row.operator}}
                        </template>
                    </el-table-column>

                </el-table>
            </div>
            <div class="pagePanel">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[20, 50, 100, 150, 200]"
                        :page-size="20"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageTotal">
                </el-pagination>
            </div>
        </div>
    </div>

</template>

<script>
    import Vue from "vue";
    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {manager} from "../../common/administrator/managerMixin";
    import managerConst from "../../common/administrator/managerConst";
    import {Loading} from "element-ui";
    import PartnerSelectorComp from "../../components/administrator/PartnerSelectorComp";
    import {getRankList} from "../../network/manager/rankRequest";
    import {getGroupList} from "../../network/manager/groupRequest";
    import {uploadFile} from "../../network/manager/commonRequest";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import {
        getApplyList,
        updateApplyDisplay,
        updateApplyStatus, updateApplyStatusCancel2Checking,
        updateApplyStatusCancelWrongResult
    } from "../../network/manager/applyRequest";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import {manualTgameResult} from "../../network/manager/tgame365Request";
    import AgentEditToast from "../../components/administrator/UserPanel/AgentEditToast";
    import sportsConst from "../../common/sportsConst";

    Vue.prototype.$userEditToast = UserEditToast
    Vue.prototype.$agentEditToast = AgentEditToast

    export default {
        name: "ManagerApplyExchange",
        components: {DateSelectorComp, PartnerSelectorComp, ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        mixins: [manager],
        data() {
            return {
                applyCash: {user: {}},
                applyCashList: [],
                pageNum: 1,
                pageSize: 20,
                orderBy: 'id DESC',
                pageTotal: 0,
                selectedPartnerId: null,
                totalCount: 0,
                totalUserCount: 0,
                totalCash: 0,
                watingamount:0,
                checkingamount:0,

            }
        },
        methods: {
            search() {
                this.getApplyCashList();
            },
            editUser(id, mode) {
                this.$userEditToast({'userId': id, 'mode': mode})
            },
            editAgent(id, mode) {
                this.$agentEditToast({'agentId': id, 'mode': mode})
            },
            getApplyCashList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.watingamount = 0
                this.checkingamount = 0
                this.applyCash.startDate = this.startDate;
                this.applyCash.endDate = this.endDate;
                this.applyCash.applyType = managerConst.ApplyCash.ATYPE_EXCHANGE
                getApplyList(this.applyCash, this.pageNum, this.pageSize).then(res => {
                    this.pageTotal = res.data.total;
                    this.applyCashList = res.data.data.cashList;
                    this.totalCash = res.data.data.totalCash
                    this.totalCount = res.data.data.totalCount
                    this.totalUserCount = res.data.data.totalUserCount
                    loadingInstance.close();
                    for(let i=0;i<this.applyCashList.length;i++){
                        let ex = this.applyCashList[i];
                        if(ex.status == sportsConst.APPLY_STATUS_CHECKING){
                            this.checkingamount += ex.amount;
                        }
                        if(ex.status == sportsConst.APPLY_STATUS_WAITING){
                            this.watingamount += ex.amount;
                        }
                    }
                })
            },
            updateStatus(id, status, text, consiterecharge) {
                this.$confirm(text+'처리 하시겠습니까?', '', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let record = {}
                    record.id = id
                    record.status = status
                    record.applyType = managerConst.ApplyCash.ATYPE_EXCHANGE;
                    record.consiterecharge = consiterecharge;
                    updateApplyStatus(record).then(res=>{
                        if(res.data.success){
                            this.search()
                        }else {
                            this.$message({
                                type: 'error',
                                duration: 1000,
                                message: res.data.msg
                            });
                        }
                        loadingInstance.close()
                    })


                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '취소되였습니다'
                    });
                });
            },
            updateDisplay(id,display){
                updateApplyDisplay({id:id,display:display}).then(res=>{
                    this.search()
                })
            },
            updateStatusCancelWrongResult(id){

                this.$confirm('취소처리 하시겠습니까?', '', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    updateApplyStatusCancelWrongResult(id).then(res=>{
                        this.search()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '취소되였습니다'
                    });
                });

            },
            updateStatusCancel2Checking(id){
                this.$confirm('`확인중`상태로 변경 하시겠습니까?', '', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    updateApplyStatusCancel2Checking(id).then(res=>{
                        this.search()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '취소되였습니다'
                    });
                });

            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.search();
            },
            setPartnerId(partnerId) {
                this.pageNum = 1
                this.applyCash = {}
                this.applyCash.partnerId2 = partnerId
                this.search()
            },
        },
        created() {
            this.startDate = this.$moment().format('yyyy-MM-DD')
            this.endDate = this.$moment().format('yyyy-MM-DD')
            this.search()
        },
        watch: {}
    }
</script>

<style scoped>

</style>